import {createContext, useContext, useRef, createRef, useCallback} from "react";
import { useFrame } from "@react-three/fiber";
import { useBox, useConeTwistConstraint } from "@react-three/cannon";
import { createRagdoll } from "../helpers/createRagdoll";
import { useDragConstraint } from "../helpers/Drag";
import { Block } from "../helpers/Block";

const ANGLES = 0.01;
const { shapes, joints } = createRagdoll(10, ANGLES, ANGLES, 1e-10);
const parent = createContext({ ref: createRef() }); // type Object3D

const BodyPart = ({ config, children, render, name, ...props }) => {
  const { color, args, mass, position } = shapes[name];
  const { ref: parentRef } = useContext(parent);
  const [ref] = useBox(() => ({
    mass,
    args,
    position,
    linearDamping: 0.8,
    angularDamping: 0.8,
    ...props,
  }));
  useConeTwistConstraint(ref, parentRef, {
    ...config,
    collideConnected: true,
    maxForce: 1e10,
    maxMultiplier: 1e10,
  });
  const binder = useDragConstraint(ref);
  const bind = name === "willy" ? binder : {};

  const onPointerOver = useCallback (() => {
    name === "willy" && (document.body.style.cursor = "grab");
  }, [name]);

  const onPointerOut = useCallback (() => {
    name === "willy" && (document.body.style.cursor = "auto");
  }, []);

  return (
    <>
      <Block
        castShadow
        receiveShadow
        ref={ref}
        {...props}
        {...bind}
        scale={args}
        name={name}
        color={color}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        {render}
      </Block>
      <parent.Provider value={{ ref: ref }}>{children}</parent.Provider>
    </>
  );
};

function Face() {
  const mouth = useRef();
  const eyes = useRef();
  useFrame((state) => {
    eyes.current.position.y = Math.sin(state.clock.elapsedTime * 2) * 0.1;
    mouth.current.scale.y = (1 + Math.sin(state.clock.elapsedTime * 2)) * 0.6;
  });
  return (
    <>
      <group ref={eyes}>
        <Block
          position={[-0.3, 0.1, 0.5]}
          args={[0.2, 0.1, 0.1]}
          color="black"
          transparent
          opacity={0.8}
        />
        <Block
          position={[0.3, 0.1, 0.5]}
          args={[0.2, 0.1, 0.1]}
          color="black"
          transparent
          opacity={0.8}
        />
      </group>
      <Block
        ref={mouth}
        position={[0, -0.2, 0.5]}
        args={[0.3, 0.05, 0.1]}
        color="#700000"
        transparent
        opacity={0.8}
      />
    </>
  );
}

export function Guy(props) {
  return (
    <BodyPart name="upperBody" {...props}>
      <BodyPart
        {...props}
        name="head"
        config={joints["neckJoint"]}
        render={<Face />}
      />
      <BodyPart {...props} name="upperLeftArm" config={joints["leftShoulder"]}>
        <BodyPart
          {...props}
          name="lowerLeftArm"
          config={joints["leftElbowJoint"]}
        />
      </BodyPart>
      <BodyPart
        {...props}
        name="upperRightArm"
        config={joints["rightShoulder"]}
      >
        <BodyPart
          {...props}
          name="lowerRightArm"
          config={joints["rightElbowJoint"]}
        />
      </BodyPart>
      <BodyPart {...props} name="pelvis" config={joints["spineJoint"]}>
        <BodyPart {...props} name="willy" config={joints["willyJoint"]} />
        <BodyPart
          {...props}
          name="upperLeftLeg"
          config={joints["leftHipJoint"]}
        >
          <BodyPart
            {...props}
            name="lowerLeftLeg"
            config={joints["leftKneeJoint"]}
          />
        </BodyPart>
        <BodyPart
          {...props}
          name="upperRightLeg"
          config={joints["rightHipJoint"]}
        >
          <BodyPart
            {...props}
            name="lowerRightLeg"
            config={joints["rightKneeJoint"]}
          />
        </BodyPart>
      </BodyPart>
    </BodyPart>
  );
}
